import { t } from '@lingui/macro';
import type { NewsletterSignUpResponse } from '@/pages/api/newsletter-signup';
import type { StoreLocale } from '@/root/constants';

/** Handle submitting to the newsletter endpoint. */
export type NewsletterVariables = {
  email: string;
  dateOfBirth?: string;
  source: NewsletterOrigin;
  customerType: NewsletterCustomerType;
  firstName?: string;
  lastName?: string;
};

type NewsletterCustomerType = 'subscriberPro' | 'subscriberCon';

type NewsletterSenderSystem = 'shopify_uk' | 'shopify_us' | 'shopify_ch';

type NewsletterCountryCodeISO2 = 'GB' | 'US' | 'CH';

type NewsletterOrigin =
  | 'Z_SHOPIFY_FORM'
  | 'Z_SHOPIFY_POPUP'
  | 'Z_SHOPIFY_SIGNUPPAGE';

type NewsletterOptInLevel = 'singleOptIn' | 'optOut';

type NewsletterPayload = {
  controlRecord: {
    senderSystem: NewsletterSenderSystem;
    receiverSystem: 'sapmkt';
    masterSystem: 'sapmkt';
    senderMessageCreation: string;
    canonicalDataObject: 'customer';
    canonicalDataObjectVersion: 'v1.0';
    interfaceMode: 'delta';
    interfaceAction: 'upsert';
  };
  customer: {
    senderId: string;
    receiverId: string;
    masterId: string;
    customerType: NewsletterCustomerType;
    createdAt: string;
    updatedAt: string;
    nonUnifiedAttributes: [];
    firstName: string;
    lastName: string;
    address: {
      countryCodeISO2: NewsletterCountryCodeISO2;
    };
    dateOfBirth?: string;
    professionalTypeBusiness: 'Nail';
    marketingConsent: [
      {
        communicationMedium: 'all';
        optInLevel: NewsletterOptInLevel;
        contactInfo: string;
        updatedAt: string;
        origin: NewsletterOrigin;
        subscriptions: [
          {
            subscriptionTopic: 'all';
            subscription: 'subscribed';
            updatedAt: string;
          }
        ];
        interests: [];
      }
    ];
  };
};

const NEWSLETTER_LOCALES_TO_STORES = new Map<
  StoreLocale,
  NewsletterSenderSystem
>([
  ['en-GB', 'shopify_uk'],
  ['en-US', 'shopify_us'],
  ['en-CH', 'shopify_ch'], // @TODO Confirm store
  ['fr-CH', 'shopify_ch'], // @TODO Confirm store
  ['de-CH', 'shopify_ch'], // @TODO Confirm store
  ['it-CH', 'shopify_ch'], // @TODO Confirm store
]);

export async function newsletterFetcher(
  variables: NewsletterVariables & { locale: StoreLocale }
): Promise<NewsletterSignUpResponse> {
  const date = new Date().toISOString();
  const {
    email,
    customerType,
    source = 'Z_SHOPIFY_FORM',
    locale,
    firstName,
    lastName,
    dateOfBirth,
  } = variables;
  const store = NEWSLETTER_LOCALES_TO_STORES.get(
    locale
  ) as NewsletterSenderSystem;
  const countryCodeISO2 = locale.split('-').at(1) as NewsletterCountryCodeISO2;

  const payload: NewsletterPayload = {
    controlRecord: {
      senderSystem: store,
      receiverSystem: 'sapmkt',
      masterSystem: 'sapmkt',
      senderMessageCreation: date,
      canonicalDataObject: 'customer',
      canonicalDataObjectVersion: 'v1.0',
      interfaceMode: 'delta',
      interfaceAction: 'upsert',
    },
    customer: {
      senderId: email,
      receiverId: email,
      masterId: email,
      customerType,
      createdAt: date,
      updatedAt: date,
      nonUnifiedAttributes: [],
      firstName: firstName || '',
      lastName: lastName || '',
      address: {
        countryCodeISO2,
      },
      ...(dateOfBirth ? { dateOfBirth } : {}),
      professionalTypeBusiness: 'Nail',
      marketingConsent: [
        {
          communicationMedium: 'all',
          optInLevel: 'singleOptIn',
          contactInfo: email,
          updatedAt: date,
          origin: source,
          subscriptions: [
            {
              subscriptionTopic: 'all',
              subscription: 'subscribed',
              updatedAt: date,
            },
          ],
          interests: [],
        },
      ],
    },
  };

  try {
    const res = await fetch(`${window.location.origin}/api/newsletter-signup`, {
      method: 'POST',
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    const json: NewsletterSignUpResponse = await res.json();

    return json;
  } catch (error) {
    console.log('ERROR:', error);

    return {
      errors: [
        {
          message: t({
            id: 'newsletter.generalError',
            message: 'There was an error submitting the form',
          }),
          code: '',
        },
      ],
    };
  }
}
